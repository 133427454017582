









import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ProfilePhoto",
  props: {
    url: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const profilePhotoPath = computed(() => {
      if (props.url.includes("https://firebasestorage") ||
        props.url.includes("https://storage.googleapis.com") ||
        props.url === "/img/learnlink_icon_large.png") {
        return props.url;
      }
      return "/img/nophoto.jpeg";
    });

    return {
      profilePhotoPath
    };
  },
});
