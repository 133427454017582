







































































































import { defineComponent, PropType } from "@vue/composition-api";
import CompletedItem from "@/components/app/layout/CompletedItem.vue";
import { capitalize } from "@/helpers/stringUtils";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "./ProfilePhoto.vue";

export default defineComponent({
  components: {
    CompletedItem,
    ProfilePhoto,
  },
  props: {
    profile: {
      type: Object as PropType<Learnlink.Profile.default>,
      required: true,
    },
  },
  setup: ({ profile }) => {
    return {
      user: {
        ...profile,
        levelsTaught: profile.levels.map(capitalize).join(", "),
        hoursTaught: Math.round(profile.minutesSold / 60),
        name: `${profile.firstName} ${profile.lastName}`,
      },
    };
  },
});
